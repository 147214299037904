import { Routes, Route } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

import "./App.css";
import NavigationBar from "./portal/navigation/NavigationBar";
import { useState } from "react";

declare const process: {
  env: {
    REACT_APP_STORE_PORTAL_URL: string;
    REACT_APP_ADMIN_PORTAL_URL: string;
    REACT_APP_WAREHOUSE_PORTAL_URL: string;
    REACT_APP_PORTAL_TITLE: string;
  };
};

document.title = process.env.REACT_APP_PORTAL_TITLE;

function App(): JSX.Element {
  return (
    <div className="demo-container">
      <div className="demo-store-container">
        <iframe src={process.env.REACT_APP_STORE_PORTAL_URL} className="demo-store-frame" title="Store Demo"></iframe>
        <NavigationBar portalName={process.env.REACT_APP_PORTAL_TITLE} />
      </div>
      <div className="demo-right-vertical-container">
        <iframe src={process.env.REACT_APP_ADMIN_PORTAL_URL} className="demo-admin-frame" title="Admin Demo"></iframe>
        <iframe src={process.env.REACT_APP_WAREHOUSE_PORTAL_URL} className="demo-warehouse-frame" title="Admin Demo"></iframe>
      </div>
    </div>
  );
}

export default App;
