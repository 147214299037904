import { useState } from "react";
import { Button, Modal, Nav, Navbar } from "react-bootstrap";
import AboutManager from "../about/AboutManager";
import "./NavigationBar.css";

interface INavigationBar {
  portalName: string;
}

export default function NavigationBar({ portalName }: INavigationBar): JSX.Element {
  const [showArchitectureDiagram, setShowArchitectureDiagram] = useState(false);
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const CloseArchitectureDiagramHandler = () => setShowArchitectureDiagram(false);
  const ShowArchitectureDiagramHandler = () => setShowArchitectureDiagram(true);

  const CloseHowItWorksHandler = () => setShowHowItWorks(false);
  const ShowHowItWorksHandler = () => setShowHowItWorks(true);

  const CloseAboutHandler = () => setShowAbout(false);
  const ShowAboutHandler = () => setShowAbout(true);

  return (
    <div className="navigation-main">
      <Navbar className="navigation-bar" collapseOnSelect bg="light" variant="light">
        <Navbar.Brand className="navbar-brand">
          <label className="navbar-brand-text-label">{portalName}</label>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="navigation-container">
          <Nav className="navigation-section ml-auto">
            <div className="nav-vertical-delimiter"></div>
            <Nav.Item className="nav-item-generic">
              <Nav.Link eventKey="1" className="nav-link-item link-item-hover" onClick={ShowArchitectureDiagramHandler}>
                <span className="nav-normal-text-label">Architecture Diagram</span>
              </Nav.Link>
            </Nav.Item>
            <div className="nav-vertical-delimiter"></div>
            <Nav.Item className="nav-item-generic">
              <Nav.Link eventKey="2" className="nav-link-item link-item-hover" onClick={ShowHowItWorksHandler}>
                <span className="nav-normal-text-label">How it works</span>
              </Nav.Link>
            </Nav.Item>
            <div className="nav-vertical-delimiter"></div>
            <Nav.Item className="nav-item-generic">
              <Nav.Link eventKey="3" className="nav-link-item link-item-hover">
                <span className="nav-normal-text-label">Instructions</span>
              </Nav.Link>
            </Nav.Item>
            <div className="nav-vertical-delimiter"></div>
            <Nav.Item className="nav-item-generic">
              <Nav.Link eventKey="4" className="nav-link-item link-item-hover" onClick={ShowAboutHandler}>
                <span className="nav-normal-text-label">About</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal fullscreen={true} centered show={showArchitectureDiagram} onHide={CloseArchitectureDiagramHandler}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-label">Detailed Architecture Diagram</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nav-modal-diagram-container">
            <img className="nav-modal-image" src="/architecture_diagram.png" alt="Architecture Diagram" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseArchitectureDiagramHandler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal fullscreen={true} centered show={showHowItWorks} onHide={CloseHowItWorksHandler}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-label">How it works</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nav-modal-diagram-container">
            <img className="nav-modal-image" src="/event_flow.gif" alt="Complete Event Flow" />
          </div>
          <div className="nav-modal-diagram-container">
            <img className="nav-modal-image" src="/complete_event_flow.png" alt="Complete Event Flow" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseHowItWorksHandler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered size="xl" show={showAbout} onHide={CloseAboutHandler}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-label">About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AboutManager />
        </Modal.Body>
        <Modal.Footer>
          <div className="about-copyright">© 2022, Amazon Web Services, Inc. or its affiliates. All rights reserved.</div>
          <Button variant="secondary" onClick={CloseAboutHandler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
